
import Vue, { PropType } from "vue";

import http from "@/http";

import MyLifeline from "@/components/building/MyLifeline.vue";

import { Building, Snackbar } from "@/interfaces";

export default Vue.extend({
  components: {
    MyLifeline
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    }
  },
  methods: {
    async click() {
      for (const lifeline of this.building.lifelines) {
        const url = `lifelines/${lifeline.id}/lifeline_notes`

        await http.post(url, {
          note: lifeline.note
        })
      }

      const snackbar: Snackbar = {
        show: true,
        content: "保存しました",
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);
    }
  }
});
