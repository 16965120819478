
import Vue, { PropType } from "vue";

import { Building } from "@/interfaces";

export default Vue.extend({
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    },
    editable: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  methods: {
    getType(type: number) {
      switch (type) {
        case 1:
          return "電気";
        case 2:
          return "水道";
        case 3:
          return "下水道";
        default:
          return "ガス";
      }
    }
  }
});
